
































































































































































































































import DomainWorkspaceToolbar from '@/components/navigation/DomainWorkspaceToolbar.vue';
import { BAD_FIVE9_VERSION_MESSAGE } from '@/lib/constants';
import { DomainModel, useDomainApi } from '@/module/api/domain';
import { isModelExpired } from '@/module/api/domo';
import { ExportStateName, useExportApi } from '@/module/api/export';
import {
LoadJobModel,
LoadJobType,
LoadJobWizardSetupModel,
SourceType,
useLoadJobApi
} from '@/module/api/load-job';
import { useAuth, userCanUseImportSyncJob } from '@/module/auth';
import router from '@/router';
import DomainDialog from '@/views/app/domains/DomainDialog.vue';
import ExportJobSetupDialog, {
useExportJobSetupDialog
} from '@/views/app/export/ExportJobSetupDialog.vue';
import { computed, defineComponent, reactive, ref, toRefs, unref } from '@vue/composition-api';
import Vue from 'vue';
import { createToastInterface } from 'vue-toastification';

const { add: createAndRunExportJob, isLoading: isExportLoading } = useExportJobSetupDialog();

const { isLoading: isExportJobLoading } = useExportApi();

const { assertionContext } = useAuth();

const toast = createToastInterface({ maxToasts: 3 });

const {
  getItems: getDomains,
  items: domains,
  selectItem: selectDomain,
  emptyDomainModel,
  getItem: getDomain,
  getVersions,
  isLoading: isDomainLoading
} = useDomainApi();

const {
  createItem: createLoadJob,
  updateItem: updateLoadJob,
  selectedItem: selectedLoadJob,
  selectItem: selectLoadJob,
  getItem: getLoadJob,
  getItems: getLoadJobs,
  emptyLoadJobModel,
  isLoading: isLoadJobLoading,
  setupLoadJob,
  applyWizardSetup
} = useLoadJobApi();

export enum DialogMode {
  VIEW = 'view',
  EDIT = 'edit',
  ADD = 'add'
}

const emptyState = () => {
  return {
    dialog: false,
    currentDomainUuid: '',
    currentJobUuid: '',
    isMonolith: false,
    title: 'New Import',
    mode: DialogMode.ADD,
    isPreparing: false,
    havePrepared: false,
    isPreapredFailed: false,
    label: '',
    validForm: false,
    test: '',
    selectedDomain: emptyDomainModel() as DomainModel,
    isSynchronized: false,
    wizard: {
      step: 1,
      stepsCompleted: 0
    },
    haveFetchedSourceDomain: false,
    showDeveloperButton: false,
    isExportDisabled: false
  };
};

const currentDomain = ref<Partial<DomainModel>>(emptyDomainModel());
const currentLoadJob = ref<Partial<LoadJobModel>>(emptyLoadJobModel());
const domainDialog = ref<any>();

const state = reactive(emptyState());

const selectedDomainCanBeUsed = computed(() => {
  if (!state.selectedDomain?.mostRecentExtractJob) {
    return false;
  }
  if (isModelExpired(state.selectedDomain.mostRecentExtractJob)) {
    return false;
  }
  if (state.selectedDomain.mostRecentExtractJob?.state !== ExportStateName.DONE) {
    return false;
  }
  return true;
});

const selectDomainHint = computed(() => {
  if (!state.selectedDomain?.uuid) {
    return '';
  }
  const totalObjects = state.selectedDomain?.mostRecentExtractJob?.objectSummary?.total;
  const region = state.selectedDomain.region;
  const domainName = state.selectedDomain?.domainName;
  const domainId = state.selectedDomain?.domainId;
  return `${domainName} (${domainId}, ${region}) ${
    totalObjects ? 'with ' + totalObjects + ' objects' : ' with NO objects'
  }`;
});

const viewDomain = () => {
  domainDialog.value.view(unref(currentDomain));
};

const reset = () => {
  Object.assign(state, emptyState());
};

// TODO: Should only be able to select domains that have a recent export job?
const prepareBaseJob = async (): Promise<LoadJobModel | undefined> => {
  if (!state.currentJobUuid || state.currentJobUuid === '') {
    return await createLoadJob({
      domainUuid: currentDomain.value?.uuid,
      type: LoadJobType.IMPORT,
      label: `Import ${state.selectedDomain.label}`
    });
  } else {
    return await getLoadJob({ uuid: state.currentJobUuid, forceRefresh: true });
  }
};

const updateLoadJobWizard = async (baseLoadJob: LoadJobModel): Promise<number | undefined> => {
  // Note: "source" is the Domain to import from.
  // "destination" is going to be the most recent extract job on the Domain.
  const updateLoadJobParams: Partial<LoadJobWizardSetupModel> = {
    isComplete: true,
    step: 1,
    isSynchronized: state.isSynchronized,
    source: {
      type: SourceType.DOMAIN,
      uuid: state.selectedDomain.uuid,
      extractJobUuid: state.selectedDomain?.mostRecentExtractJob?.uuid || ''
    }
  };
  return await setupLoadJob(baseLoadJob.uuid, updateLoadJobParams);
};

const prepareJob = async () => {
  (state.wizard.step = 2), (state.wizard.stepsCompleted = 1);
  Vue.$log.debug({ currentLoadJob });
  if (!state.selectedDomain?.uuid || state.selectedDomain?.uuid === '') {
    const errMessage = 'Not enough information about the source domain to continue';
    Vue.$log.error(errMessage);
    toast.error(errMessage);
    return;
  }

  try {
    state.isPreparing = true;
    const baseLoadJob = await prepareBaseJob();
    if (!baseLoadJob || !baseLoadJob.uuid) {
      const errMessage = `Failed to create import job for domain ${currentDomain.value?.domainName}`;
      Vue.$log.error(errMessage);
      toast.error(errMessage);
      return;
    }
    // Set state for later use when we want to route the user
    state.currentJobUuid = baseLoadJob.uuid;
    // TODO: Remove extract job uuid from source ref once its no longer needed.
    const updateLoadJobResponse = await updateLoadJobWizard(baseLoadJob);
    Vue.$log.debug({ updateLoadJobResponse });

    if (
      !updateLoadJobResponse ||
      (updateLoadJobResponse && updateLoadJobResponse < 200) ||
      updateLoadJobResponse > 299
    ) {
      const errMessage = 'Unable to prepare job setup.';
      Vue.$log.debug(errMessage);
      toast.error(errMessage);
      return;
    }
    const res = await applyWizardSetup(baseLoadJob.uuid);
    if (!res || (res && res < 200) || res > 299) {
      const errMessage = 'Failed to finalize job';
      Vue.$log.error(errMessage);
      toast.error(errMessage);
      state.isPreapredFailed = true;
      return undefined;
    }
    state.havePrepared = true;
    toast.success('Prepared job.');
  } catch (err) {
    Vue.$log.error(err);
    toast.error('Failed to prepare import job');
    state.isPreapredFailed = true;
  } finally {
    state.isPreparing = false;
  }
};

const isLatestExportJobRunning = computed(() => {
  return state.selectedDomain?.mostRecentExtractJob?.state === ExportStateName.IN_PROGRESS;
});

const initializeData = async (domainUuid: string, jobUuid?: string) => {
  const domain = await getDomain(domainUuid);
  const versions = await getVersions(domainUuid);
  state.isMonolith = versions && versions.isMonolith ? true : false;
  currentDomain.value = domain;
  getDomains();
  if (jobUuid) {
    Vue.$log.debug(`Initializing Job with Passed In Job Uuid ${jobUuid}`);
    state.currentJobUuid = jobUuid;
  }
};

const refreshItems = () => {
  initializeData(state.currentDomainUuid, state.currentJobUuid);
};

const handleSuccessfulDomainExport = () => {
  state.isExportDisabled = true;
  state.haveFetchedSourceDomain = true;
  setTimeout(() => {
    refreshItems();
  }, 2000);

  setTimeout(() => {
    state.isExportDisabled = false;
  }, 3000);
};

const selectableDomains = computed(() => {
  return unref(domains).filter((domain: DomainModel) => {
    return domain.uuid !== state.currentDomainUuid;
  });
});

const goToLoadJobDetailPage = () => {
  Vue.$log.debug('Going to route', {
    name: 'WorkspaceImportJobDetail',
    params: {
      uuid: state.currentDomainUuid,
      jobUuid: state.currentJobUuid
    }
  });
  router.push({
    name: 'WorkspaceImportJobDetail',
    params: {
      uuid: state.currentDomainUuid,
      jobUuid: state.currentJobUuid
    }
  });
};

export default defineComponent({
  name: 'ImportJobImportSetupWizard',
  components: { ExportJobSetupDialog, DomainDialog, DomainWorkspaceToolbar },
  props: ['uuid', 'jobUuid'],
  setup(props) {
    reset();
    state.currentDomainUuid = props.uuid;
    state.currentJobUuid = props.jobUuid;

    const backLink = `/domain/${props.uuid}/workspace`;
    const exportJobsHistoryPageLink = `/domain/${props.uuid}/workspace/export-jobs`;
    const loadJobsHistoryPageLink = `/domain/${props.uuid}/workspace/load-jobs`;

    initializeData(props.uuid, props.jobUuid);
    return {
      ...toRefs(state),
      backLink,
      isDomainLoading,
      isLoadJobLoading,
      isLatestExportJobRunning,
      reset,
      selectableDomains,
      domainDialog,
      handleSuccessfulDomainExport,
      createAndRunExportJob,
      isExportLoading,
      DialogMode,
      selectDomainHint,
      prepareJob,
      currentDomain,
      currentLoadJob,
      exportJobsHistoryPageLink,
      loadJobsHistoryPageLink,
      selectedLoadJob,
      selectedDomainCanBeUsed,
      goToLoadJobDetailPage,
      viewDomain,
      isExportJobLoading,
      BAD_FIVE9_VERSION_MESSAGE,
      canUseSync: computed(() => {
        return userCanUseImportSyncJob(assertionContext.value?.roles);
      })
    };
  }
});
