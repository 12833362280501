var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("domain-workspace-toolbar", {
                attrs: {
                  uuid: _vm.currentDomainUuid,
                  backLink: _vm.backLink,
                  subtitle: "Create an Import Job"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { loading: _vm.isDomainLoading } },
        [
          _c("v-card-title", [_vm._v("Create an Import Job")]),
          _c("v-card-subtitle", [
            _c("div", { staticClass: "text-subtitle-1" }, [
              _vm._v(
                " Going through this wizard will setup an import job that can be used to load the selected Domain's data into your Domain. "
              )
            ])
          ]),
          _c(
            "v-card-subtitle",
            [
              _vm.isMonolith
                ? _c(
                    "v-alert",
                    {
                      staticClass: "elevation-2",
                      attrs: {
                        type: "error",
                        "colored-border": "",
                        border: "top"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.BAD_FIVE9_VERSION_MESSAGE) + " ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-stepper",
            {
              staticClass: "elevation-0",
              attrs: { vertical: "" },
              model: {
                value: _vm.wizard.step,
                callback: function($$v) {
                  _vm.$set(_vm.wizard, "step", $$v)
                },
                expression: "wizard.step"
              }
            },
            [
              _c(
                "v-stepper-step",
                { attrs: { complete: _vm.wizard.step > 1, step: "1" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "d-flex flex-nowrap" },
                            [
                              _c("div", { staticClass: "mt-1" }, [
                                _vm._v("Choose a Source to import data from")
                              ]),
                              _c(
                                "v-icon",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.havePrepared,
                                      expression: "havePrepared"
                                    }
                                  ],
                                  staticClass: "ml-2",
                                  attrs: { color: "success" }
                                },
                                [_vm._v("mdi-check-circle")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "1" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  hint: _vm.selectDomainHint,
                                  items: _vm.selectableDomains,
                                  disabled:
                                    _vm.haveFetchedSourceDomain ||
                                    _vm.havePrepared,
                                  "item-text": "label",
                                  label: "Select Domain",
                                  "prepend-icon": "mdi-earth",
                                  "persistent-hint": "",
                                  "return-object": "",
                                  "single-line": ""
                                },
                                model: {
                                  value: _vm.selectedDomain,
                                  callback: function($$v) {
                                    _vm.selectedDomain = $$v
                                  },
                                  expression: "selectedDomain"
                                }
                              })
                            ],
                            1
                          ),
                          _vm.canUseSync
                            ? _c(
                                "v-col",
                                [
                                  _c("v-checkbox", {
                                    staticClass: "ml-8",
                                    attrs: {
                                      disabled:
                                        _vm.haveFetchedSourceDomain ||
                                        _vm.havePrepared,
                                      "persistent-hint": "",
                                      hint:
                                        "Domain sync may result in DELETE operations on your Domain.",
                                      label: "Sync Domain?"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "message",
                                          fn: function(ref) {
                                            var message = ref.message
                                            return [
                                              _c("span", {
                                                domProps: {
                                                  innerHTML: _vm._s(message)
                                                }
                                              })
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3913813263
                                    ),
                                    model: {
                                      value: _vm.isSynchronized,
                                      callback: function($$v) {
                                        _vm.isSynchronized = $$v
                                      },
                                      expression: "isSynchronized"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { align: "center", justify: "center" } },
                        [
                          _c(
                            "v-col",
                            [
                              _vm.selectedDomainCanBeUsed
                                ? _c(
                                    "v-alert",
                                    {
                                      staticClass: "elevation-2",
                                      attrs: {
                                        border: "top",
                                        "colored-border": "",
                                        type:
                                          "" +
                                          (_vm.isSynchronized
                                            ? "warning"
                                            : "info")
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " By clicking prepare, the import job will be prepared. You will have the opportunity to review before running the job. "
                                      ),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.isSynchronized,
                                              expression: "isSynchronized"
                                            }
                                          ],
                                          staticClass: "font-weight-bold"
                                        },
                                        [
                                          _c("br"),
                                          _vm._v(
                                            "A synchronized import job may result in DELETE operations on your Domain when it is run. "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              !_vm.selectedDomainCanBeUsed &&
                              _vm.selectedDomain.uuid
                                ? _c(
                                    "v-alert",
                                    {
                                      staticClass: "elevation-2",
                                      attrs: {
                                        border: "top",
                                        "colored-border": "",
                                        type: "warning"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        [
                                          _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.isLatestExportJobRunning
                                                    ? "You cannot use this domain because it does not have a completed export. However, there is currently an export job in progress. Please wait for that job to finish first."
                                                    : "You cannot use this domain because it has never been exported or the most recent Snapshot is expired."
                                                ) +
                                                " "
                                            )
                                          ]),
                                          !_vm.selectedDomainCanBeUsed &&
                                          _vm.selectedDomain.uuid &&
                                          !_vm.haveFetchedSourceDomain
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass: "ml-2",
                                                  attrs: {
                                                    color: "primary",
                                                    outlined: "",
                                                    small: "",
                                                    disabled:
                                                      _vm.isLatestExportJobRunning ||
                                                      _vm.isExportJobLoading ||
                                                      _vm.isExportDisabled
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.createAndRunExportJob(
                                                        _vm.selectedDomain
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.isLatestExportJobRunning ||
                                                        _vm.isExportJobLoading
                                                        ? "Snapshotting"
                                                        : "New Snapshot"
                                                    ) + " "
                                                  ),
                                                  _vm.isLatestExportJobRunning ||
                                                  _vm.isExportJobLoading
                                                    ? _c(
                                                        "v-progress-circular",
                                                        {
                                                          staticClass: "ml-2",
                                                          attrs: {
                                                            indeterminate: "",
                                                            size: "20",
                                                            width: "5"
                                                          }
                                                        }
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.haveFetchedSourceDomain
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass: "ml-2",
                                                  attrs: {
                                                    color: "primary",
                                                    outlined: "",
                                                    small: "",
                                                    router: "",
                                                    to:
                                                      _vm.exportJobsHistoryPageLink
                                                  }
                                                },
                                                [_vm._v("Check Export Status")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    disabled: !_vm.selectedDomainCanBeUsed,
                                    color: "primary"
                                  },
                                  on: { click: _vm.prepareJob }
                                },
                                [_vm._v(" Prepare ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-stepper-step",
                { attrs: { complete: _vm.wizard.step > 2, step: "2" } },
                [_vm._v(" Review ")]
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "2" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c("div", { staticClass: "text-caption-1" }, [
                              _vm._v(
                                " Your Import Job " +
                                  _vm._s(
                                    _vm.isPreparing
                                      ? "is being prepared. This may take a few minutes. Please stay on this page until the preparation is complete."
                                      : "has been prepared. Review the import job operations and fix issues before running the job."
                                  ) +
                                  " "
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _vm.isLoadJobLoading
                                ? _c("v-progress-linear", {
                                    attrs: {
                                      indeterminate: _vm.isLoadJobLoading,
                                      value: 100
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _vm.havePrepared
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        title: "View the list of jobs"
                                      },
                                      on: { click: _vm.goToLoadJobDetailPage }
                                    },
                                    [_vm._v(" View Job ")]
                                  )
                                : _vm._e(),
                              _vm.isPreapredFailed
                                ? _c(
                                    "v-alert",
                                    {
                                      staticClass: "elevation-2",
                                      attrs: { type: "error" }
                                    },
                                    [
                                      _vm._v(
                                        "Failed to prepare job! Prepare a new job. If the issue persists, contact support."
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm.showDeveloperButton
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "light darken-1",
                        outlined: "",
                        text: ""
                      },
                      on: { click: _vm.reset }
                    },
                    [_vm._v(" Reset ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c("export-job-setup-dialog", {
            on: { "dialog:export:saved": _vm.handleSuccessfulDomainExport }
          }),
          _c("domain-dialog", { ref: "domainDialog" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }